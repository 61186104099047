export enum OnboardingStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum SMSOnboardingStep {
  ABOUT_SMS_1 = 'about',
  SMS_STEP_1 = 'sms-1',
  SMS_STEP_2 = 'sms-2',
  SMS_STEP_3 = 'sms-3',
}
