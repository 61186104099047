import { BlockStack, Box, Card, Text, useBreakpoints } from '@shopify/polaris';

export const MaintenancePage = () => {
  const { smDown } = useBreakpoints();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card>
        <Box padding='800' width={smDown ? '80vw' : '40vw'} minWidth='100%'>
          <BlockStack inlineAlign='center' gap='400'>
            <img
              src='/static/svg/MaintenanceOwly.svg'
              alt='maintenance owly'
              width='200px'
            />
            <Text variant='headingXl' as='h4' alignment='center'>
              We are currently under maintenance
            </Text>
            <Text as='p' variant='bodyMd' tone='subdued' alignment='center'>
              We will be back soon!
            </Text>
          </BlockStack>
        </Box>
      </Card>
    </div>
  );
};
