/* eslint-disable no-bitwise */
import { CSSProperties } from 'react';
import { FormWidgetType } from 'src/lib/constants';
import { getTreeNodeById } from 'src/modules/optins/util/treeUtils';
import {
  FONT_SIZE_MAPPING,
  ReservedContainerNodeId,
  ReservedElementNodeId,
  ROOT_NODE_ID,
  SIZES,
} from './constants';
import {
  BaseTreeNode,
  Direction,
  Fill,
  FormWidgetTheme,
  Size,
  TreeNode,
  TreeNodeAlignment,
  TreeNodeByType,
  TreeNodeType,
} from './types';

export const transformAlignmentToFlexProps = (
  alignment?: TreeNodeAlignment,
  direction?: Direction,
): CSSProperties => {
  if (!alignment || alignment?.length === 0) {
    return {};
  }

  const flexProps: Record<string, string> = {};
  const alignmentArr = typeof alignment === 'string' ? [alignment] : alignment;

  if (direction === 'LR') {
    alignmentArr.forEach(alignmentType => {
      switch (alignmentType) {
        case 'center':
          flexProps.alignItems = 'center';
          break;
        case 'left':
          flexProps.justifyContent = 'flex-start';
          break;
        case 'right':
          flexProps.justifyContent = 'flex-end';
          break;
        case 'bottom':
          flexProps.alignItems = 'flex-end';
          break;
        case 'top':
          flexProps.alignItems = 'flex-start';
          break;
        default:
          return flexProps;
      }

      return {};
    });

    return flexProps;
  }

  alignmentArr.forEach(alignmentType => {
    switch (alignmentType) {
      case 'center':
        flexProps.justifyContent = 'center';
        break;
      case 'left':
        flexProps.alignItems = 'flex-start';
        break;
      case 'right':
        flexProps.alignItems = 'flex-end';
        break;
      case 'bottom':
        flexProps.justifyContent = 'flex-end';
        break;
      case 'top':
        flexProps.justifyContent = 'flex-start';
        break;

      default:
        return flexProps;
    }

    return {};
  });

  return flexProps;
};

export const getBackgroundImageProps = (url?: string): CSSProperties => {
  return url
    ? {
        backgroundImage: `url("${url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    : {};
};

export const getFillAmount = (fill?: Fill) => {
  if (typeof fill === 'string') {
    return fill;
  }

  if (typeof fill === 'boolean') {
    return fill ? '50%' : '';
  }

  return '50%';
};

export const getInputTypeByNode = (nodeId: string) => {
  switch (nodeId) {
    case 'email':
      return 'email';
    case 'phone':
      return 'text';
    default:
      return 'text';
  }
};

export const getFontProps = (
  size?: Size,
  defaultSize = 14,
): React.CSSProperties => {
  const lineHeightMultiplier = 1.2;
  const defaultProps: React.CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: `${defaultSize}px`,
    lineHeight: `${defaultSize * lineHeightMultiplier}px`,
    paddingBlockEnd: '0px',
  };

  if (!size) {
    return defaultProps;
  }

  const bottomPadding =
    {
      '4xl': '10px',
      '3xl': '10px',
      '2xl': '8px',
      xl: '6px',
      lg: '2px',
      md: '0px',
      sm: '0px',
      xs: '0px',
      0: '0px',
    }[size] || '0px';

  const baseSize = FONT_SIZE_MAPPING[size] || defaultSize;

  const fontWeight = baseSize > 16 ? 'bold' : 'normal';

  return {
    ...defaultProps,
    fontSize: `${baseSize}px`,
    lineHeight: `${baseSize * lineHeightMultiplier}px`,
    fontWeight,
    paddingBlockEnd: bottomPadding,
  };
};

export const isHeadingText = (size: Size) => {
  return FONT_SIZE_MAPPING[size] >= FONT_SIZE_MAPPING['2xl'];
};

export const getTextColorProps = (color?: string) => {
  return color || '#000';
};

export const getSizePropByIndex = (index: number): Size => SIZES[index] ?? 'md';

export const getIndexBySizeProp = (size: Size): number =>
  SIZES.indexOf(size) === -1 ? 3 : SIZES.indexOf(size);

export const getPaddingProps = (size?: Size): React.CSSProperties => {
  if (!size) {
    return { padding: '6px' };
  }

  const baseSize =
    {
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
      '2xl': 12,
      '3xl': 14,
      '4xl': 16,
      '5xl': 20,
      '6xl': 26,
      '0': 0,
    }[size] ?? 6;

  return {
    padding: `${baseSize}px`,
  };
};

export const getBorderWidthProps = (borderWidth?: Size) => {
  if (!borderWidth) {
    return 0;
  }

  const baseWidth =
    {
      '0': 0,
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 5,
      '2xl': 6,
      '3xl': 7,
      '4xl': 8,
      '5xl': 9,
      '6xl': 10,
    }[borderWidth] ?? 0;

  return baseWidth;
};

export const getBorderProps = (
  borderCol?: string,
  borderWidth?: Size,
): CSSProperties => {
  if (!borderCol || !borderWidth) {
    return {};
  }

  return {
    border: `${getBorderWidthProps(borderWidth)}px solid ${borderCol}`,
  };
};

export const getTextOverflowProps = (isFlyout: boolean): CSSProperties => {
  return {
    whiteSpace: isFlyout ? 'nowrap' : 'normal',
    // leave some gap for the close icon in teaser
    width: isFlyout ? 'calc(100% - 25px)' : 'unset',
  };
};

export const getFlexDirectionProps = (
  isSmallScreen: boolean,
  dir?: Direction,
): CSSProperties => {
  if (isSmallScreen) {
    return {
      flexDirection: 'column',
    };
  }

  return {
    flexDirection: dir === 'LR' ? 'row' : 'column',
  };
};

export const getAdjustedInnerRadiusProps = (
  outerRadius?: Size,
  padding?: Size,
) => {
  const outerRadiusValue = getRadiusProps(outerRadius);
  const paddingValue = getRadiusProps(padding);

  const adjustedRadius = Math.abs(outerRadiusValue - paddingValue);

  return adjustedRadius === 0 ? paddingValue / 2 : adjustedRadius;
};

export const getBoxRenderProps = (
  node: TreeNodeByType<TreeNodeType.BOX>,
  isSmallScreen: boolean,
  rootNodeAttr?: TreeNodeByType<TreeNodeType.BOX>['attr'],
): React.CSSProperties => {
  const {
    align,
    dir,
    bgUrl: bgURL,
    pad,
    bgColor,
    css,
    fill,
    hidden,
    radius,
    borderCol,
    borderWidth,
  } = node.attr || {};

  const shouldHideBgImage = bgURL && isSmallScreen;

  const alignmentProps = transformAlignmentToFlexProps(align, dir);
  const flexDirProps = getFlexDirectionProps(isSmallScreen, dir);
  const bgProps = !shouldHideBgImage ? getBackgroundImageProps(bgURL) : {};
  const paddingProps = getPaddingProps(pad);
  const fillAmount = getFillAmount(fill);
  const borderProps = getBorderProps(
    borderCol || '#ffffff',
    borderWidth || '0',
  );
  let borderRadius = 0;
  let displayProp = 'flex';
  const considerHidden = typeof node.attr.hidden === 'boolean' ? hidden : false;

  if (considerHidden) {
    displayProp = 'none';
  } else if (shouldHideBgImage) {
    displayProp = 'none';
  }

  if (
    bgURL &&
    (!rootNodeAttr?.pad || rootNodeAttr?.pad !== '0') &&
    rootNodeAttr?.radius &&
    rootNodeAttr?.radius !== '0'
  ) {
    borderRadius = getAdjustedInnerRadiusProps(
      rootNodeAttr?.radius || '0',
      rootNodeAttr?.pad || '0',
    );
  } else if (!bgURL) {
    borderRadius = getRadiusProps(radius || '0');
  }

  return {
    position: 'relative',
    background:
      node.id === ROOT_NODE_ID ? bgColor || '#fff' : bgColor || 'inherit',
    borderRadius: `${borderRadius}px`,
    display: displayProp,
    flex: fillAmount,
    flexShrink: bgURL ? 0 : 1,
    // adding this because flexbox container is buggy when expanding on height of children (might change in the future)
    minHeight: bgURL ? '200px' : 'unset',
    // adding minWidth: 0 to fix overflow issue and flex shrink issue
    // ref: https://defensivecss.dev/tip/flexbox-min-content-size/
    minWidth: 0,
    overflow: node.id === ROOT_NODE_ID ? 'hidden' : 'unset',
    ...borderProps,
    ...flexDirProps,
    ...paddingProps,
    ...alignmentProps,
    ...bgProps,
    ...(css || {}),
  };
};

export const getRadiusProps = (radius?: Size) => {
  const baseSize =
    {
      '0': 0,
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
      '2xl': 12,
      '3xl': 14,
      '4xl': 16,
      '5xl': 20,
      '6xl': 26,
    }[radius] || 0;

  return baseSize;
};

export const isImageHidden = (node: TreeNode) => {
  const imageNode = getTreeNodeById(node, ReservedContainerNodeId.IMAGE);

  return typeof imageNode?.attr?.hidden === 'boolean'
    ? imageNode.attr.hidden
    : false;
};

export const getWidgetDimensionProps = (
  isSmallScreen: boolean,
  noMinHeight = false,
  theme?: Partial<FormWidgetTheme>,
): Record<FormWidgetType, CSSProperties> => {
  if (isSmallScreen) {
    return {
      [FormWidgetType.STEP_1]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.STEP_2]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.TEASER]: {
        width: '360px',
        height: '64px',
      },
    };
  }

  return {
    [FormWidgetType.STEP_1]: {
      width: theme?.width ? `${theme?.width}px` : '530px',
      minHeight: noMinHeight ? 'unset' : '500px',
    },
    [FormWidgetType.STEP_2]: {
      width: theme?.width ? `${theme?.width}px` : '530px',
      minHeight: noMinHeight ? 'unset' : '500px',
    },
    [FormWidgetType.TEASER]: {
      width: '360px',
      height: '64px',
    },
  };
};

export const isCustomFieldNode = (node: TreeNode | BaseTreeNode | string) =>
  (typeof node === 'string' ? node : node.id).startsWith(
    ReservedElementNodeId.CUSTOM_FIELD,
  );

export const isDarkColor = (hex: string) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128;
};
