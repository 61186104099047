import { createModel } from '@rematch/core';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
import {
  cancelPlanAPI,
  changePlanAPI,
  fetchPricingAPI,
  validateCouponAPI,
} from 'src/lib/api/pricing';
import { handleRedirect } from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { BillingFrequency } from '../constants';
import { findCouponPlanIndex, getBillingDiscountFrequency } from '../utils';
import { Plan } from './types';

type MonthlyPlans = {
  free: Array<Plan>;
  business: Array<Plan>;
  enterprise: Array<Plan>;
};

type AnnualPlans = {
  free: Array<Plan>;
  business: Array<Plan>;
  enterprise: Array<Plan>;
};

type RecommendedPlans = {
  monthly: Plan;
  annnual: Plan;
};

type Override = {
  extraImpressions?: number;
};

export type Topup = {
  impressions_limit: number;
  name: string;
  price: number;
  sku: string;
};

export type UTMMedium =
  | 'top_nav'
  | 'profile_dropdown'
  | 'abandoned_cart'
  | 'browse_abandonment'
  | 'flash_sale'
  | 'hero_image'
  | 'segmentation'
  | 'shipping_notification'
  | 'smart_delivery'
  | 'impression_milestone_banner'
  | 'impression_milestone_popup'
  | 'impression_milestone_email'
  | 'post_campaign_overconsumed_popup'
  | 'topup'
  | 'upgrade_tile'
  | 'locked_logo'
  | null;

type BusinessAndEnterprisePlansConfig = Pick<
  AnnualPlansConfig,
  'tagline' | 'discount'
>;

type AnnualPlansConfig = {
  tagline: string;
  discount: number;
  enterprise: BusinessAndEnterprisePlansConfig;
  business: BusinessAndEnterprisePlansConfig;
};

type CouponCodeRule = {
  discountType: 'percentage' | 'amount';
  percentageOff: number;
  amountOff: number;
  currency: string;
  duration: string;
  durationInMonths: number;
  appliesTo: {
    planTypes?: Array<'business' | 'enterprise'>;
    plans?: Array<string>;
    billingInterval: 'all' | 'monthly' | 'annual';
  };
};

type PricingState = {
  isOpen: boolean;
  error: AnyObject;
  billingFrequency: BillingFrequency.Monthly | BillingFrequency.Annual;
  isAnnualPricingEnabled: boolean;
  hasExhaustedImpression: boolean;
  coupon: {
    code: string;
    isFetching: boolean;
    description: string;
    expiryDate: string | null;
    rule: CouponCodeRule;
  };
  enableBookACall: boolean;
  scheduleMeeting: boolean;
  newPlanType: string | null;
  annualPlansConfig: AnnualPlansConfig;
  utmMedium: UTMMedium;
  utmSource: any;
  override: Override;
  topups: Array<Topup>;
  selectedIndex: {
    business: number;
    enterprise: number;
  };
  plans: {
    isFetching: boolean;
    current: Plan | null;
    monthly: MonthlyPlans;
    annual: AnnualPlans;
    recommended: RecommendedPlans | null;
  };
};

const couponCodeRuleInitial: CouponCodeRule = {
  discountType: 'percentage',
  percentageOff: 0,
  amountOff: 0,
  currency: '',
  durationInMonths: 0,
  duration: '',
  appliesTo: {
    planTypes: [],
    plans: [],
    billingInterval: 'all',
  },
};

export const initialCouponCodeState = {
  isFetching: false,
  expiryDate: null,
  code: null,
  description: '',
  rule: couponCodeRuleInitial,
};

const initialState: PricingState = {
  isOpen: false,
  utmMedium: null,
  utmSource: null,
  error: {},
  billingFrequency: BillingFrequency.Monthly,
  isAnnualPricingEnabled: false,
  hasExhaustedImpression: false,
  enableBookACall: false,
  newPlanType: null,
  scheduleMeeting: false,
  coupon: initialCouponCodeState,
  annualPlansConfig: null,
  override: {},
  selectedIndex: {
    business: 0,
    enterprise: 0,
  },
  topups: [],
  plans: {
    isFetching: true,
    current: null,
    monthly: { free: [], business: [], enterprise: [] },
    annual: { free: [], business: [], enterprise: [] },
    recommended: null,
  },
};

const pricing = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFetching(state, isFetching: boolean) {
      return {
        ...state,
        plans: {
          ...state.plans,
          isFetching,
        },
      };
    },
    setState(state, payload: Partial<PricingState>) {
      return {
        ...state,
        ...payload,
      };
    },
    closePricing(state) {
      return {
        ...state,
        isOpen: false,
        scheduleMeeting: false,
      };
    },
    setBillingFrequency(state: PricingState, payload) {
      return {
        ...state,
        billingFrequency: payload,
        plans: {
          ...state.plans,
        },
      };
    },
    setError(state: PricingState, payload: AnyObject | null) {
      return {
        ...state,
        error: payload,
      };
    },
    setCoupon(state: PricingState, payload: AnyObject | null) {
      return {
        ...state,
        coupon: {
          ...state.coupon,
          ...payload,
        },
      };
    },
    setPlanIndex(
      state: PricingState,
      payload: {
        planType: string;
        index: number;
      },
    ) {
      return {
        ...state,
        selectedIndex: {
          ...state.selectedIndex,
          [payload.planType]: payload.index,
        },
      };
    },
    scheduleMeeting(state, payload: boolean) {
      return {
        ...state,
        isOpen: payload ? false : state.isOpen,
        scheduleMeeting: payload,
      };
    },
  },
  effects: dispatch => ({
    async fetchPricing(payload = {}) {
      this.setFetching(true);

      const res = await fetchPricingAPI();
      if (res.error) {
        dispatch.saveToast.showError('Error loading pricing');
        return;
      }

      const selectedIndex = {
        business: 0,
        enterprise: 0,
      };

      const recommended = res.data.recommended_plans;

      if (!payload.recommended) {
        // Find the index of the recommended plan and set it in redux
        const monthlyRecommendedPlan = res.data?.recommended_plans?.monthly;
        // Since monthly is selected by default we check for monthly recommended plan
        if (monthlyRecommendedPlan && res.data.plans.monthly) {
          const plans =
            res.data.plans.monthly[monthlyRecommendedPlan.plan_type] || [];
          const index = plans.findIndex(
            plan => plan.sku === monthlyRecommendedPlan.sku,
          );

          if (index > -1) {
            selectedIndex[monthlyRecommendedPlan.plan_type] = index;
          }
        }
      } else {
        // If recommedation find the index of that recommended plan and use it instead
        const plans =
          res.data.plans.monthly[payload.recommended.plan_type] || [];
        const index = plans.findIndex(
          plan => plan.sku === payload.recommended.sku,
        );
        selectedIndex[payload.recommended.plan_type] = index >= 0 ? index : 0;
        recommended.monthly[payload.plan_type] = payload;
      }

      const monthlyBusinessPlans = res.data.plans.monthly.business;
      if (
        res.data.current_plan.impressions_limit >=
        monthlyBusinessPlans[monthlyBusinessPlans.length - 1].impressions_limit
      ) {
        selectedIndex.business = monthlyBusinessPlans.length - 1;
      }

      this.setState({
        plans: {
          isFetching: false,
          current: res.data.current_plan,
          recommended,
          monthly: res.data.plans.monthly,
          annual: res.data.plans.annual,
        },
        selectedIndex,
        topups: res.data.topups,
        enableBookACall: res.data.enable_book_a_call,
        isAnnualPricingEnabled: res.data.enable_annual_pricing,
        annualPlansConfig: res.data.annual_plans_config,
      });

      if (!isEmpty(res.data.override)) {
        this.setState({
          override: {
            extraImpressions: res.data.override.extra_impressions,
          },
        });
      }
    },

    async showPricing(payload: {
      utmMedium?: PricingState['utmMedium'];
      utmSource?: any;
      plan_type?: any;
      recommended?: { plan_type: any; sku: any };
      shouldAutoSelectPlanForUpgrade?: boolean;
    }) {
      this.setState({
        isOpen: true,
        utmMedium: payload?.utmMedium,
        utmSource: payload?.utmSource,
      });

      this.fetchPricing(payload);
    },

    async showNewPricing(payload: {
      utmMedium?: PricingState['utmMedium'];
      utmSource?: any;
    }) {
      this.setState({
        utmMedium: payload?.utmMedium,
        utmSource: payload?.utmSource,
      });

      Router.push('/pricing');
    },

    async validateCoupon(payload: string) {
      this.setCoupon({
        isFetching: true,
      });

      const { error } = await validateCouponAPI({ code: payload });

      if (error) {
        this.setCoupon({
          isFetching: false,
        });
        this.setError({
          discount: 'Invalid discount code',
        });
      } else {
        this.setCoupon({ code: '', isFetching: false });
        this.setError({});
      }
    },

    async applyCoupon(payload: string, rootState) {
      this.setError({});
      this.setCoupon({
        isFetching: true,
      });

      const { data, error } = await validateCouponAPI({
        code: payload,
      });

      if (error) {
        this.setCoupon({ isFetching: false, data: null });
        this.setError({
          discount: 'Invalid discount code',
        });
        return;
      }

      dispatch.saveToast.showDone('Coupon applied');

      const {
        code,
        descriptin,
        expiry_date: expiryDate,
        rule: {
          discount_type: discountType,
          percentage_off,
          amount_off,
          currency,
          duration,
          duration_in_months,
          applies_to: {
            plan_types: planTypes,
            plans,
            billing_interval: billingInterval,
          },
        },
      } = data;

      this.setCoupon({
        isFetching: false,
        code,
        descriptin,
        expiryDate,
        rule: {
          discountType,
          percentageOff: parseFloat(percentage_off),
          amountOff: parseFloat(amount_off),
          currency,
          duration,
          durationInMonths: parseInt(duration_in_months, 10),
          appliesTo: {
            planTypes,
            plans,
            billingInterval,
          },
        },
      });

      // Select the corresponding index or the given SKU
      const { pricing } = rootState;
      const monthlyPlans = pricing.plans.monthly;
      const annualPlans = pricing.plans.annual;

      const [businessIndex, businessBillingFrequency] = findCouponPlanIndex({
        monthlyPlans: monthlyPlans.business,
        annualPlans: annualPlans.business,
        couponCodeSkus: plans,
        currentBillingFrequency: pricing.billingFrequency,
        couponBillingFrequency: billingInterval,
      });

      const [enterpriseIndex, enterpriseBillingFrequency] = findCouponPlanIndex(
        {
          monthlyPlans: monthlyPlans.enterprise,
          annualPlans: annualPlans.enterprise,
          couponCodeSkus: plans,
          currentBillingFrequency: pricing.billingFrequency,
          couponBillingFrequency: billingInterval,
        },
      );

      const billingFrequency = getBillingDiscountFrequency({
        businessBillingFrequency,
        enterpriseBillingFrequency,
        enterpriseIndex,
        businessIndex,
        couponBillingFrequency: billingInterval,
      });

      this.setState({
        billingFrequency,
        selectedIndex: {
          business:
            businessIndex === null
              ? pricing.selectedIndex.business
              : businessIndex,
          enterprise:
            enterpriseIndex === null
              ? pricing.selectedIndex.enterprise
              : enterpriseIndex,
        },
      });
    },

    async changePlan(payload: AnyObject, rootState) {
      const { coupon, utmMedium, utmSource } = rootState.pricing;
      const isFreePlan = payload.sku === 'default';

      this.setState({
        newPlanType: payload.plan_type,
      });

      if (!isFreePlan) {
        const res = await changePlanAPI({
          billing_plan_sku: payload.sku,
          utm_source: utmSource || 'pushowl',
          utm_medium: utmMedium,
          coupon_code: coupon ? coupon.code : null,
        });

        if (res.error) dispatch.saveToast.showError('Error changing plan');
        else {
          handleRedirect(res.data.confirmation_url);
          return;
        }
      } else {
        const res = await cancelPlanAPI();
        if (!res.error) {
          const { user } = rootState.user;

          this.closePricing();
          Router.push(
            `/?${new URLSearchParams({
              authToken: user.token,
              subdomain: user.website.subdomain,
              platform: user.website.platform,
            })}`,
          );

          return;
        }
      }
      this.setState({
        newPlanType: null,
        utmMedium: null,
      });
    },
  }),
});

export default pricing;
