import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models';

type SmsModelState = {
  onboarding: {
    countries: string[];
  };
  credits: {
    active: boolean;
  };
};

const initialState: SmsModelState = {
  onboarding: {
    countries: [],
  },
  credits: {
    active: false,
  },
};

const sms = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    dispatch,
  }),
  reducers: {
    setState(state, payload: Partial<SmsModelState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default sms;
